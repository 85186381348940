<template>
  <div class="teacher">
    <list-template
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        :search-config="searchConfig"
        :current-page="page"
        @onSearch="onSearch"
        @onReset="onReset"
        @onChangePage="changeCurrentPage"
        ref="myTable"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"details","meta":{"title":"数据看板","keepAlive":true}}},
  computed: {
    ...mapState(['page'])
  },
  data() {
    return {
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'teacher',
          placeholder: '搜索教师'
        },
        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '筛选校区',
          options: [],
          label: 'school_name',
          value: 'id',
          change: val => {
            this.searchConfig[2].options = [];
            // let myTab = this.$refs.myTable;
            // myTab.search.task_cycle_id = '';
            // myTab.search.subject_id = '';
            this.$_axios2.get('api/1v1/statistics/get-task-cycle?school_id=' + val).then(res => {
              this.searchConfig[2].options = res.data.data
            })
          }
        },
        {
          prop: 'task_cycle_id',
          tag: 'select',
          placeholder: '筛选任务周期',
          options: [],
          value: 'id',
          label: 'name',
          change: val => {
            this.searchConfig[3].options = []
            let myTab = this.$refs.myTable;
            if (myTab.search.subject_id) myTab.search.subject_id = ''
            this.$_axios2.get('api/1v1/statistics/subject?task_cycle_id=' + val).then(res => {
              this.searchConfig[3].options = res.data.data
            })
          }
        },
        {
          prop: 'subject_id',
          tag: 'select',
          placeholder: '筛选科目',
          options: [],
          value: 'id',
          label: 'subject_name'
        },
        {
          prop: 'status',
          tag: 'select',
          placeholder: '是否完成任务',
          options: [
            {
              label: '是',
              value: 1
            },
            {
              label: '否',
              value: 2
            }
          ]
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'teacher',
          label: '教师姓名',
          width: 120
        },
        {
          prop: 'subject',
          label: '辅导科目',
          width: 90
        },
        {
          prop: 'task_cycle',
          label: '任务周期名称',
          width: 240
        },
        {
          prop: 'status',
          label: '是否完成任务',
          width: 120
        },
        {
          prop: 'coach_number',
          label: '已完成辅导次数（完成率）'
        },
        {
          prop: 'coach_time',
          label: '累计辅导时长'
        },
        {
          prop: 'school',
          label: '校区'
        }
      ],
      search: null,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: [],
      // 删除宿舍的id
      dormitoryId: ''
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this
      this.$_axios2.get('api/1v1/statistics/completed', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.data
        this.total = res.data.data.total
      })
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 弹窗确定按钮
    confirm() {
      deleteExam(this.dormitoryId).then(res => {
        this.$message.success('删除成功')
        this.dialogVisible = false
        this.getData()
      })
    },
    onReset() {
      this.$_axios.get('site/fist-subject').then(res => {
        this.searchConfig[3].options = res.data.data
      })
      this.$_axios2.get('api/1v1/statistics/get-task-cycle').then(res => {
        this.searchConfig[2].options = res.data.data
      })
    }
  },
  created() {
    this.$_axios.get('site/school').then(res => {
      this.searchConfig[1].options = res.data.data
    })
    this.$_axios.get('site/fist-subject').then(res => {
      this.searchConfig[3].options = res.data.data
    })
    this.$_axios2.get('api/1v1/statistics/get-task-cycle').then(res => {
      this.searchConfig[2].options = res.data.data
    })
  }
}
</script>

<style scoped lang="scss">
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }
}
</style>
